<template>
  <section>
    <div class="content-header">
      <h2>Restorative justice on campuses </h2>
    </div>
    <div class="content-wrapper">
      <p>In the context of the criminal justice system, restorative justice is:</p>
      <div class="panel">
        <p>“An approach to justice that focuses on repairing the harm caused by crime while holding the offender responsible for his or her actions, by providing an opportunity for the parties directly affected by a crime—victim(s), offender and community—to identify and address their needs in the aftermath of a crime, and seek a resolution that affords healing, reparation and reintegration, and prevents future harm.” (Cormier, 2002)</p>
      </div>
      <p class="pt-4">On campuses, restorative justice is a community-building technique that focuses on mediation rather than retribution. Creating an educational/restorative environment means going against traditional judicial/rules-based approaches.</p>
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="bg-orange">Judicial/rules based</th>
            <th scope="col" class="bg-green">Educational/restorative</th>
          </tr>
        </thead>
        <tbody style="border-top: none;">
          <tr>
            <td class="text-orange">Rights-based</td>
            <td class="text-green">Care-based</td>
          </tr>
          <tr>
            <td class="text-orange">Relies on external control</td>
            <td class="text-green">Relies on internal responsibility/empowerment</td>
          </tr>
          <tr>
            <td class="text-orange">Formal adjudication procedures; adversarial</td>
            <td class="text-green">Restorative procedures; co-operative</td>
          </tr>
          <tr>
            <td class="text-orange">Geared to common denominator</td>
            <td class="text-green">High expectations</td>
          </tr>
          <tr>
            <td class="text-orange">Top-down hierarchical, imposed</td>
            <td class="text-green">Stakeholder-driven, open to challenge</td>
          </tr>
          <tr>
            <td class="text-orange">Priority is following rules and procedures</td>
            <td class="text-green">Priority is developing a caring community</td>
          </tr>
          <tr>
            <td class="text-orange">Transparent, consistent, easy to scale up</td>
            <td class="text-green">Requires understanding of values, buy-in</td>
          </tr>
          <tr>
            <td class="text-orange">“Don’t get caught” </td>
            <td class="text-green">“We depend on each other”</td>
          </tr>
        </tbody>
      </table>
      <h3 class="mt-3">What does a restorative conversation sound like?</h3>
      <p>Restorative conversations generally have five features.</p>
      <ul>
        <li>An individual tells the story</li>
        <li>Impact is acknowledged</li>
        <li>An intervener assesses the learning and willingness to proceed</li>
        <li>All parties mutually devise a solution</li>
        <li>All parties make a commitment</li>
      </ul>
      <p>Those leading these conversations should be assertive and direct about issues, focus on behaviour and address reactions. They should also look for individuals’ motivation and encourage understanding of the impact on the complainant, the community and themselves.</p>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
